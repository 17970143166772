import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import ModelList from "../../components/ModelList"
import React from "react"
import Seo from "../../components/seo"

const BassesElectricPage = () => {
  const data = useStaticQuery(graphql`
  {
    solidBody: allMdx(
      sort: {order: ASC, fields: frontmatter___model}
      filter: {frontmatter: {collections: {eq: "basses", in: "solid body"}}}
    ) {
      nodes {
        ...modelData
      }
    }
    # acousticElectric: allMdx(
    #   sort: {order: ASC, fields: frontmatter___model}
    #   filter: {frontmatter: {collections: {eq: "basses", in: "acoustic-electric"}}}
    # ) {
    #   nodes {
    #     ...modelData
    #   }
    # }
  }
  

  `);

  const solidBody = data?.solidBody?.nodes.map((x) => x.frontmatter);
  const acousticElectric = data?.acousticElectric?.nodes.map((x) => x.frontmatter);



  return (
    <Layout >
      <Seo title="Talman electric basses" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">Talman<br /> electric basses</h1>

        <section className="flex flex-col items-center">
          <h2 className="text-2xl mt-28">coming soon...</h2>
        </section>

        <section id="solid-body" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Solid body <a className="hash-link" href="#solid-body">#</a>
          </h2>
          <ModelList list={solidBody} />
        </section>

        <section id="acoustic-electric" className="flex flex-col items-center">
          <h2 className="model-cat-heading">Acoustic-electric <a className="hash-link" href="#acoustic-electric">#</a></h2>
          <ModelList list={acousticElectric} />
        </section>
      </div>
    </Layout>
  )
}


export default BassesElectricPage