import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"
import React from "react"

const ModelList = ({list}) => {
  if (list && list.length) {
    return (<ul className="">
          {list.map(x => (
              <li className="flex text-2xl sm:text-3xl  lg:text-6xl  items-center mb-6">
                <Link to={x.slug}>{x.model}</Link>
                <Link to={x.slug} className="ml-6 border-none max-w-xs md:max-w-md lg:max-w-xl transform duration-300 transition-transform hover:scale-105"><GatsbyImage image={getImage(x.swatch)} alt={`${x.model} guitar`} className="h-full block pixelate" /></Link>
              </li>
          ))}
    </ul>)
  }

  return <span className="w-full"></span> 
};


export default ModelList